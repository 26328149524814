import React from 'react';
import { Link } from 'react-router-dom'

export default function LmsContent() {
    return (
        <section className="benifits-area pt-60 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="benifits-left position-relative mb-5">
                            <div>
                                <img
                                    src="assets/img/lms-img-01.jpg"
                                    className="img-fluid text-center"
                                    alt="girl"
                                />
                            </div>
 
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="benifits-right mt-5 mt-lg-0">
                            <div className="benifits-right-content mb-0">
                                <h2 className="mb-3 title3">
                                Empowering Leaders, Enriching <span className="gr-text">Learning</span>
                                </h2>
                                <p className="m-0">
                                Embrace a new era of employee training management with Zentixs Talent, our innovative solution that redefines learning through unconventional technology. Bid farewell to manual tracking systems that hinder training leaders from staying updated on employee progress and identifying gaps. Our single-digitized tool perpetuates compliance requirements while prioritizing employee development.
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="single-benifit mb-4 mb-lg-0">
                                        <div className="benifit-icon one">
                                            <img src="assets/img/bi-1.png" alt="icon" />
                                        </div>
                                        <h5>Elevating Training Dynamics</h5>
                                        <p className="m-0">
                                        The platform streamlines onboarding, compliance, and skill development through assessments, NDCA management, and targeted training. It offers transparency and ease of use for both employees and trainers.
                                        </p>                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="single-benifit">
                                        <div className="benifit-icon two">
                                            <img src="assets/img/bi-2.png" alt="icon" />
                                        </div>
                                        <h5>Unveiling Audit Excellence</h5>
                                        <p className="m-0">
                                        Zentixs Talent's system provides secure, 21 CFR Part 11 compliant employee training management with timestamped audit trails. It logs user actions and record changes for accountability and compliance.
                                       </p>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

