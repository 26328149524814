import React from 'react';
import SectionTitle from '../common/SectionTitle';

const DeskContenFive = () => {
  return (
    <>
      <section className='feature-section-two '>
        <div className='container'>
          <div className='row align-items-center  justify-content-center'>
          <div className='col-lg-4 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/desk3.png'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
               <h2 className='title2'>Empowered agents</h2>
               <p className='lead'>Ace customer service at every stage of the customer journey and delight them every single day. Desk empowers agents with powerful tools and helpful context, so they remain productive, confident, and organized.
</p>
              </div>
            </div>
         
          </div>
        </div>
      </section>
    </>
  );
};

export default DeskContenFive;
