import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqTwo = () => {
  return (
    <>
      <section className='faq-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12 col-12'>
              <SectionTitle title='Frequently Asked Questions'  centerAlign/>
              
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-10 col-12'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2 active'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='true'
                    >
                      How does back pricing work?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse show'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Dramatically formulate cross-unit products with
                      web-enabled action items. Quickly maximize extensible
                      methods of empowerment without out-of-the-box initiatives.
                      Proactively myocardinate functional total linkage rather
                      than seamless information. Holisticly fabricate timely
                      initiatives vis-a-vis high-quality imperatives.
                      Continually deploy open-source content through
                      professional customer service.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      Can you show me an example?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Continually innovate technically sound internal or
                      "organic" sources with market positioning content.
                      Completely drive optimal intellectual capital vis-a-vis
                      global human capital. Intrinsicly administrate robust
                      materials and bleeding-edge resources. Dynamically
                      envisioneer next-generation markets vis-a-vis
                      market-driven applications
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      How do I processing I need?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Phosfluorescently deliver cooperative testing procedures
                      after integrated communities. Dramatically simplify
                      resource-leveling models with unique outsourcing.
                      Professionally simplify covalent partnerships whereas
                      market positioning best practices. Collaboratively utilize
                      magnetic technology for robust technology.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      What happens if I go over my limit?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Credibly facilitate leveraged process improvements for
                      equity invested infrastructures. Continually mesh top-line
                      human capital with backward-compatible outsourcing.
                      Rapidiously coordinate intuitive deliverables rather than
                      parallel metrics. Interactively monetize customer directed
                      convergence and parallel sources. Enthusiastically
                      architect client-centric e-services whereas granular
                      e-commerce.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                      How do I calculate how much processing I need?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Distinctively enable premier potentialities through market
                      positioning models. Distinctively extend unique
                      infomediaries without enterprise-wide ideas. Objectively
                      deploy multifunctional catalysts for change for installed
                      base content. Seamlessly create go forward convergence
                      through quality schemas. Objectively deploy cross-media
                      leadership skills through customer directed sources.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqTwo;
