import React from 'react';
import SectionTitle from '../common/SectionTitle';

const LogContentThree = () => {
  return (
    <>
      <section className='why-choose-us pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-lg-between justify-content-center align-items-center'>
            <div className='col-lg-5 col-md-7 order-1 order-lg-0'>
              <div className='why-choose-img position-relative'>
                <img
                  src='assets/img/screen/prioritization-screen.svg'
                  className='img-fluid'
                  alt='duel-phone'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
            <div className='feature-content-wrap'>
                <h2 className='title2'>Elevating Accountability of Varied Data Spans</h2>                
                  <div className='mt-5 mb-5'>
                  <h4 className='title4'>Accuracy Assurance</h4>
                  <p>The system acts as a data integrity guardian, ensuring accuracy by pausing new entries until ongoing ones finish. It stops logbook submissions during instrument maintenance, fostering precise records. This intuitive feature guarantees updated, reliable logs for a dependable activity history.</p>
                  </div>
                  <div className='mb-5'>
                  <h4 className='title4'>Precision and Flexibility</h4>
                  <p>The system enhances reliability through a review and approval workflow. Automatic timestamps add context and accuracy, while tailored user roles ensure precise authority allocation. It accommodates varied entry durations and multiple users, transforming the logbook into a dynamic and informative data repository.</p>
                  </div>
                 
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LogContentThree;
