import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const TabOne = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabHover = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <section className='feature-tab-section ptb-120 bg-light-gray-double' style={{ paddingBottom: 10 }}>
        <div className='container'>
          <div className='row justify-content-center align-content-center'>
            <div className='col-md-10 col-lg-8'>
              <SectionTitle
                title='Compliant with Regulatory and Guidelines'
                description='Zentixs is built for pharmaceuticals, research, laboratories and healthcare industries. The software is validated and complied with 21 CFR Part 11, EU Annex 11, HIPAA and GxP guidelines.'
                centerAlign
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <ul
                className='nav justify-content-center feature-tab-list-2 mb-0'
                id='nav-tab'
                role='tablist'
              >
                <li className='nav-item'>
                  <Link
                    className={`nav-link ${activeTab === 'tab-1' ? 'active' : ''}`}
                    to='#tab-1'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-1'
                    role='tab'
                    aria-selected={activeTab === 'tab-1' ? 'true' : 'false'}
                    onMouseEnter={() => handleTabHover('tab-1')}
                  >
                    Zentixs Docs
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={`nav-link ${activeTab === 'tab-2' ? 'active' : ''}`}
                    to='#tab-2'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-2'
                    role='tab'
                    aria-selected={activeTab === 'tab-2' ? 'true' : 'false'}
                    onMouseEnter={() => handleTabHover('tab-2')}
                  >
                    Zentixs Talent
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={`nav-link ${activeTab === 'tab-3' ? 'active' : ''}`}
                    to='#tab-3'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-3'
                    role='tab'
                    aria-selected={activeTab === 'tab-3' ? 'true' : 'false'}
                    onMouseEnter={() => handleTabHover('tab-3')}
                  >
                    Zentixs Sign
                  </Link>
                </li>
              </ul>
              <div className='tab-content' id='nav-tabContent'>
                <div
                  className={`tab-pane fade pt-60 ${activeTab === 'tab-1' ? 'active show' : ''}`}
                  id='tab-1'
                  role='tabpanel'
                >
                  <div className='row justify-content-center align-items-center justify-content-around'>
                    <div className='col-lg-9'>
                      <div className='feature-tab-info'>
                        <img
                          src='assets/img/docs-zentixs.jpg'
                          alt='feature tab'
                          className='img-fluid mb-4 mb-lg-0 mb-xl-0'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`tab-pane fade pt-60 ${activeTab === 'tab-2' ? 'active show' : ''}`} id='tab-2' role='tabpanel'>
                  <div className='row justify-content-center align-items-center justify-content-around'>
                    <div className='col-lg-9'>
                      <img
                        src='assets/img/traning.jpg'                        
                        alt='feature tab'
                        className='img-fluid mb-4 mb-lg-0 mb-xl-0'
                      />
                    </div>
                  </div>
                </div>
                <div className={`tab-pane fade pt-60 ${activeTab === 'tab-3' ? 'active show' : ''}`} id='tab-3' role='tabpanel'>
                  <div className='row justify-content-center align-items-center justify-content-around'>
                    <div className='col-lg-9'>
                      <div className='feature-tab-info'>
                        <img
                          src='assets/img/sign-zentixs.jpg'
                          alt='feature tab'
                          className='img-fluid mb-4 mb-lg-0 mb-xl-0'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TabOne;
