import React from 'react';
import Reveal from 'react-reveal/Reveal';


export default function LmsContentTwo() {
  return (
    
    <section className="digi-news-letter pb-120">
      <div className="container">
        <div className="bg-1 text-light rounded-custom p-4 p-md-5 p-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="digi-newsletter">             
                <h2 className='title2'>Effortless Learning Evolution</h2>
                <p className='text-dark'>
                Zentixs Talent revolutionizes companies' training methods by embracing efficient e-training, eliminating physical gatherings, and enabling remote learning. This approach streamlines GxP, SOP, Annual, and group training, saving time and effort while simplifying intricate processes. Employees stay updated on procedures and policies, aided by this innovative solution. Zentixs Talent also precisely tracks responses in lessons and activities, facilitating user progress monitoring and generating insightful statistics for annual training assessments.
                </p>       
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
            <Reveal effect="fadeinbottom">
              <div className="dg-news-img text-end">
                <img
                  src="assets/img/lms-img-02.png"
                  className="img-fluid text-end"
                  alt="pie"
                />
              </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}