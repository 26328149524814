import React from 'react';

const QmsContentFour = () => {
    return (
        <>
            <section className='ptb-120 bg-blue-grediant pb-0 mb-32'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='col-md-8'>

                            <div className="row" >
                                <div className='col-md-6'>
                                    <div>
                                        <h2 className='title2 pb-2 text-white'>Dual-Endorsement & Validation Excellence

</h2>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <p className='text-white'>
                                    Each process of software begins a validation journey, securing a dual endorsement from the relevant department head and QA department, guaranteeing meticulous quality control.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pb-0 text-center' style={{ top: '100px', position: 'relative' }}>
                        <img
                            src='assets/img/track.png'
                            alt='zentixs aboutus'
                            className='img-fluid'
                        />
                    </div>

                </div>
            </section>
        </>
    );
};

export default QmsContentFour;