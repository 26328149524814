import React from 'react';
import { Link } from 'react-router-dom';
import Reveal from 'react-reveal/Reveal';





export default function DocsContentThree() {
  return (
    <section className="digi-how-works bg-white ptb-120 pb-0">
      <div className="container">
        <div className="row justify-content-center ">
      
          <div className="col-lg-5">
          <Reveal effect="fadeInUp">

            <div className="digi-how-works-left">     
            

              <h2>
              Always available           
              </h2>              

              <p>
              A file management and collaboration solution that suits any team, <br /> industry and business size.
              </p>  
              <div className="dg-news-img">
                <img
                  src="assets/img/document-process-06.png"
                  className="img-fluid text-end"
                  alt="document process"
                />
              </div>     
            </div>
            </Reveal>
          </div>
         
          <div className="col-lg-5">
            <div className="mt-5 mt-lg-0">
              <div className="">
                <ul className="list-unstyled mb-0">
                  <li className="d-md-flex mb-4">
                    <div className="me-3">
                      <img
                        src="assets/img/icon/icon1.png"
                        className="img-fluid mb-3 mb-md-0"
                        alt="icons"
                      />
                    </div>
                    <div>
                     
                      <h3>Ready to use</h3>
                      <p className="m-0 ">
                      A comprehensive app that can be used immediately.
                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex   mb-4">
                    <div className="me-3">
                      <img
                        src="assets/img/icon/icon2.png"
                        className="mb-3 mb-md-0"
                        alt="icons"
                      />
                    </div>
                    <div>                     
                      <h3>Secure</h3>
                      <p className="m-0 ">Enterprise-grade security to ensure the complete safety of <br />your data
                      </p>
                    </div>
                  </li>
                  <li className="d-md-flex  ">
                    <div className="me-3">
                      <img
                        src="assets/img/icon/icon3.png"
                        className="mb-3 mb-md-0"
                        alt="icons"
                      />
                    </div>
                    <div>                    
                      <h3>Cloud-based</h3>
                      <p className="m-0 ">
                      Accessible from anywhere on the globe
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="shape">
                  <ul className="list-unstyled mb-0">
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
