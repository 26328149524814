import React from 'react';
import SectionTitle from '../common/SectionTitle';

const LogContentTwo = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-4 col-md-12'>
              <div className='feature-content-wrap'>
                <h2 className='title2'>Holistically manage entire lifecycle</h2>                
                  <div className='mt-5 mb-5'>
                  <h4 className='title4'>Threads of Accountability</h4>
                  <p>An audit trail that meticulously captures user interactions, modifications, and transactions, establishing transparency and accountability. This function proves indispensable for meeting regulatory compliance, offering a comprehensive view of system utilization and alterations.</p>
                  </div>                 
                  <div>
                  <h4 className='title4'>Scan for Interaction</h4>
                  <p>Simply scan the code with the scanning device to instantly retrieve and interact with the corresponding logbook, streamlining data retrieval and enhancing user convenience.</p>
                  </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/screen/feature.svg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LogContentTwo;
