import React from 'react';
import SectionTitle from '../common/SectionTitle';

const SagaContentThree = () => {
  return (
    <>
      <section className='why-choose-us pt-60 pb-120 pt-0'>
        <div className='container'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-lg-5 col-md-7 order-1 order-lg-0'>
              <div className='why-choose-img position-relative'>
                <img
                  src='assets/img/screen/screen-6.svg'
                  className='img-fluid'
                  alt='duel-phone'
                />
              </div>
            </div>
            <div className='col-lg-4 col-md-12 order-0 order-lg-1'>
              <div className='why-choose-content ml-2'>
         
                <h2 className='title3'>Prepare data from multiple sources</h2>
                <p>Connect to a variety of sources such as files, feeds, databases, data warehouses, REST APIs and more. Also use built-in connectors to import data from Zoho Analytics and Zoho CRM.
</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SagaContentThree;
