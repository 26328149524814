import React from 'react';
import Reveal from 'react-reveal/Reveal';

export default function DeskContent() {
  return (
    <section className="bg-white ptb-120 ">
      <div className="container ">
        <div className='desk-world-section'>
        <div className="row justify-content-center align-items-center">      
          <div className="col-lg-5">
          <Reveal effect="fadeInUp">

            <div className="digi-how-works-left">   
            

              <h2>
              Why use Zentixs Desk?       
              </h2>             
 
              <div className="dg-news-img">
                <img
                  src="assets/img/desk1.png"
                  className="img-fluid text-end"
                  alt="document process"
                />
              </div>     
            </div>
            </Reveal>
          </div>
         
          <div className="col-lg-5">
            <div className="mt-5 mt-lg-0">
            <div className='feature-content-wrap'>

<ul className='list-unstyled d-flex flex-wrap text-dark-gray eye-list'>
  <li className='py-1'>
 <i className="fa-sharp fa-solid fa-circle-check"></i>
    50% faster implementation than most customer service software
  </li>
  <li className='py-1'>
 <i className="fa-sharp fa-solid fa-circle-check"></i>
    Dedicated training program with shallow learning curve
  </li>
  <li className='py-1'>
 <i className="fa-sharp fa-solid fa-circle-check"></i>
    Customizable workspace that works across any industry, brand, and department
  </li>
  <li className='py-1'>
 <i className="fa-sharp fa-solid fa-circle-check"></i>
    Hassle-free migration with the help of our account executives
  </li>
  <li className='py-1'>
 <i className="fa-sharp fa-solid fa-circle-check"></i>
    No hidden costs, long-term contracts, and lock-ins
  </li>
  <li className='py-1'>
 <i className="fa-sharp fa-solid fa-circle-check"></i>
    Compliant with data protection laws, such as GDPR, HIPAA, and CCPA
  </li>

</ul>
</div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
}
