import React from 'react';
import { Link } from 'react-router-dom';

const AllproductsContent = () => {
  return (
    <>
      <section className='promo-section ptb-120 pb-0'>
        <div className='container'>
        <div className='row align-items-center  justify-content-center'>
        <div className='col-lg-10 col-md-11'>
          <div className='row'>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-docs-sop-document-review-control'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/docs.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Docs</h5>
                  <p className='mb-30 text-dark'>
                  Organize your documents with our cutting-edge solution.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-docs-sop-document-review-control">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>

            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-talent-lms-training-management'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/talent.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Talent</h5>
                  <p className='mb-30 text-dark'>
                  Cultivating quality, constructing an intelligent organization.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-talent-lms-training-management">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-track-qms-quality-management-incident-capa-change-control'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/track.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Track</h5>
                  <p className='mb-30 text-dark'>
                  Deliver innovations, streamline compliance, and focus on quality.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-track-qms-quality-management-incident-capa-change-control">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-eye-department-process-supplier-vendor-audit'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/eye.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Eye</h5>
                  <p className='mb-30 text-dark'>
                  Focusing on crucial risks that directly affect the business.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-eye-department-process-supplier-vendor-audit">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-lab-lims-analytical-bioanalytical-clinical-in-vitro'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/labs.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Labs</h5>
                  <p className='mb-30 text-dark'>
                  Enabling automated integration with compliance and security.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-lab-lims-analytical-bioanalytical-clinical-in-vitro">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-sign-electronic-digital-signature'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/sign.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Sign</h5>
                  <p className='mb-30 text-dark'>
                  Elevate your decorum with the precision of a digital sign.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-sign-electronic-digital-signature">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-caliber-instrument-equipment-asset-calibration'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/calibar.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Caliber</h5>
                  <p className='mb-30 text-dark'>
                  Maximize equipment lifecycle, efficiency and compliance.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-caliber-instrument-equipment-asset-calibration">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-log-eln-electronic-logbook'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/log.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Log</h5>
                  <p className='mb-30 text-dark'>
                  Revolutionize data handling by securely and compliantly.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-log-eln-electronic-logbook">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-sprint-project-master-schedule-study-management'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/sprint.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Sprint</h5>
                  <p className='mb-30 text-dark'>
                  Execute projects flawlessly, monitoring with precision.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-sprint-project-master-schedule-study-management">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-saga-document-sample-softcopy-archival'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/saga.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Saga</h5>
                  <p className='mb-30 text-dark'>
                  Securely consolidate and organize archived data.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-saga-document-sample-softcopy-archival">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-desk-ticket-support-helpdesk-frontdesk'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/desk.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Desk</h5>
                  <p className='mb-30 text-dark'>
                  Enhance customer service to create incredible experiences.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-desk-ticket-support-helpdesk-frontdesk">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/zentixs-ibook-inventory-chemical-product-store-management'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/ibook.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Ibook</h5>
                  <p className='mb-30 text-dark'>
                  Optimize internal operations and enrich warehouse efficiency.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/zentixs-ibook-inventory-chemical-product-store-management">Know more<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            <div className='col-lg-4 col-md-4 mb-4'>
            <Link
                to='/request-for-demo'
                className='mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-1 rounded-custom d-block overflow-hidden p-4'
              >
                <div className='position-relative connected-app-content'>
                  <div className='product-logo  p-2 d-inline-block'>
                    <img
                      src='assets/img/icon/force.svg'
                      width='35'
                      alt='integration'                      
                    />
                  </div>
                  <h5 className='mb-3'>Zentixs Force</h5>
                  <p className='mb-30 text-dark'>
                  Zentixs Force: harmonize workforce administration effortlessly.
                  </p>
                <Link className="read-more-link text-decoration-none" to="/request-for-demo">Request a Demo<i className="far fa-arrow-right ms-2"></i></Link>
                </div>              
              </Link>
            </div>
            
          </div>
          </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllproductsContent;
