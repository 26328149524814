import React from 'react';
import SectionTitle from '../common/SectionTitle';
import { Link } from 'react-router-dom';

const TrustedUsers = () => {
  return (
    <>
      <section className='cta-subscribe  ptb-120 position-relative overflow-hidden'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-8'>
              <div className='subscribe-info-wrap text-center position-relative z-2'>
                <div className='section-heading'>
                
                  <h2 className='fw-bold display-5'>Trusted by 10,000+ users worldwide.

</h2>
                  <p className='lead'>
                  Faster delivery, improved collaboration, and greater innovation
                  </p>
                </div>
          
                <Link to="/request-for-demo" className="btn btn-primary me-3">
                    Request For Demo
                  </Link>
               
              </div>
            </div>
          </div>
  
        </div>
      </section>
    </>
  );
};

export default TrustedUsers;
