import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FaqTwo from '../components/faqs/FaqTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import LmsContent from '../components/Product/LmsContent';
import CustomerBrand from '../components/customer/CustomerBrand';
import PageHeader from '../components/common/PageHeader';
import LmsContentThree from '../components/Product/LmsContentThree';
import QmsContentFour from '../components/Product/QmsContentFour';
import LmsContentTwo from '../components/Product/LmsContentTwo';
import HeroLms from '../components/Product/HeroLms';

const Lms = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Talent | Online Training Managment Software | Adrta' />
      <Navbar/>
      <HeroLms />
      <CustomerBrand  />
      <LmsContent/>
      <LmsContentTwo/>
      <LmsContentThree />     
      <FaqTwo />
      <ContactFormThree />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Lms;
