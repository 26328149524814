import React from 'react';
import SectionTitle from '../common/SectionTitle';

const LmsContentThree = () => {
  return (
    <>
      <section className='feature-section bg-2 ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-6 col-md-6'>
              <div
                className='image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0'
                data-aos='fade-right'
              >
                <img
                  src='assets/img/talent.png'
                  alt='feature img'
                  className='img-fluid shadow rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-5 col-md-6'>
              <div
                className='feature-content-wrap'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <SectionTitle
                  subtitle='Advanced Features'
                  title='Put your team on the fast track to proficiency.'
                  leftAlign
                />
                <p>Unlock compliance while prioritizing development, and modernize your training management with Zentixs Talent's innovative solution.</p>
                <ul className='list-unstyled mt-5'>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className='fas fa-bezier-curve text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Versatile Functionalities</h3>
                      <p>
                      Zentixs Talent efficiently handles a range of functions, including generating job responsibilities and CVs for employees, creating design matrices, embedding question-answer sections within SOPs, and seamlessly generating certificates.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-danger rounded me-4'>
                      <i className='fas fa-fingerprint text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Efficiency in Sight</h3>
                      <p>
                      Experience real-time insights into employee training status and reports with Zentixs Talent's executive dashboard – a user-friendly computer interface designed for intuitive navigation, featuring a thoughtfully organized graphical display.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LmsContentThree;
