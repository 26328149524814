import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OffCanvasMenu from './OffCanvasMenu';
import Fade from 'react-reveal/Fade';


const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  
  useEffect(() => {
    const stickyheader = document.querySelector('.main-header');
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header
        className={`main-header ${navDark ? ' ' : ''
          } w-100`}
      >

        <nav
          className={`navbar navbar-expand-xl z-10 ${navDark ? 'navbar-dark' : 'navbar-light'
            } sticky-header ${scroll > headerTop ? 'affix' : ''}`}
        >
          <div className="container ">
            <Link
              to="/"
              className="navbar-brand mb-md-0 text-decoration-none"
            >
              <img
                src="assets/img/logo-white.png"
                alt="logo"
                className="img-fluid logo-white"
              />
              <img
                src="assets/img/logo-color.svg"
                alt="logo"
                className="img-fluid logo-color"
              />
            </Link>
            <Link
              className="navbar-toggler position-absolute right-0 border-0"
              to="#offcanvasWithBackdrop"
              role="button"
            >
              <span
                className="fa-sharp fa-regular fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </Link>

            <div className="collapse navbar-collapse">
          

              <ul className="nav me-auto ms-4 my-lg-0 navbar-nav-scroll main-menu">
            
                <li className="nav-item dropdown  has-megamenu">
                
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Products
                  </Link>
                  
                  <div className="dropdown-menu megamenu border-0 py-0 bg-white">                
                    <div className='container'>
                      <div className="rounded-custom width-full align-items-center align-items-stretch">
                        <div className="dropdown-grid-item">                      
                          {/* <h6 className="drop-heading mb-4 mt-3">Software</h6> */}
                          <div className="row d-flex align-items-top product-menu">
                          <Fade top>
                            <div className='col-md-6 col-lg-3'>
                              <Link to="/zentixs-docs-sop-document-review-control" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/docs.svg'
                                    alt='Zentixs docs'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Docs</div>
                                  <p className="drop-decription">
                                 Organize your documents with our cutting-edge solution.
                                    {/* It has provision to create a dynamic workflow for reviewing and finalizing the document.   */}
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                              <Link to="/zentixs-talent-lms-training-management" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/talent.svg'
                                    alt='Zentixs talent'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Talent</div>
                                  <p className="drop-decription">
                                  Cultivating quality, constructing an intelligent organization.
                                    {/* All the aspect of employee training as per assigned matrix and personal record is covered with training summary. */}
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                              <Link to="/zentixs-track-qms-quality-management-incident-capa-change-control" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/track.svg'
                                    alt='Zentixs track'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Track</div>
                                  <p className="drop-decription">
                                  Deliver innovations, streamline compliance, and focus on quality.
                                    {/* It involves CAPA, Deviation, CR, Incident Management procedure in an easy manner. */}
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <Link to="/zentixs-eye-department-process-supplier-vendor-audit" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/eye.svg'
                                    alt='Zentixs eye'
                                    className='img30'
                                  />
                                </span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Eye</div>
                                  <p className="drop-decription">
                                  Focusing on crucial risks that directly affect the business.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                              <Link to="/zentixs-lab-lims-analytical-bioanalytical-clinical-in-vitro" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/labs.svg'
                                    alt='Zentixs labs'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Labs</div>
                                  <p className="drop-decription">
                                  Enabling automated integration with compliance and security.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                              <Link to="/zentixs-sign-electronic-digital-signature" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/sign.svg'
                                    alt='Zentixs sign'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Sign</div>
                                  <p className="drop-decription">
                                  Elevate your decorum with the precision of a digital sign.
                                    {/* In place of keeping self busy on hard copies for just minute work, this is compatible using drag and drop action to make it digital signature. */}
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                              <Link to="/zentixs-caliber-instrument-equipment-asset-calibration" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/calibar.svg'
                                    alt='Zentixs calibar'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Caliber</div>
                                  <p className="drop-decription">
                                  Maximize equipment lifecycle, efficiency and compliance.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                              <Link to="/zentixs-log-eln-electronic-logbook" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/log.svg'
                                    alt='Zentixs log'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Log</div>
                                  <p className="drop-decription">
                                  Revolutionize data handling by securely and compliantly.
                                  </p>
                                </div>
                              </Link>
                            </div>

                            <div className="col-md-6 col-lg-3">
                              <Link to="/zentixs-sprint-project-master-schedule-study-management" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/sprint.svg'
                                    alt='Zentixs sprint'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Sprint</div>
                                  <p className="drop-decription">
                                  Execute projects flawlessly, monitoring with precision.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className='col-md-6 col-lg-3'>
                              <Link to="/zentixs-saga-document-sample-softcopy-archival" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/saga.svg'
                                    alt='Zentixs saga'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Saga</div>
                                  <p className="drop-decription">
                                  Securely consolidate and organize archived data.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <Link to="/zentixs-desk-ticket-support-helpdesk-frontdesk" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/desk.svg'
                                    alt='Zentixs desk'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Desk</div>
                                  <p className="drop-decription">
                                  Enhance customer service to create incredible experiences.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <Link to="/zentixs-ibook-inventory-chemical-product-store-management" className="dropdown-link">
                                <span>
                                  <img
                                    src='assets/img/icon/ibook.svg'
                                    alt='Zentixs ibook'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Ibook</div>
                                  <p className="drop-decription">
                                  Optimize internal operations and enrich warehouse efficiency.
                                  </p>
                                </div>
                              </Link>
                            </div>
                            </Fade>
                          </div>

                          <Link className="read-more-link text-decoration-none" to="/AllProduct">Know more about all product <i className="far fa-arrow-right ms-2"></i></Link>                        

                        </div>
                      </div>
                    </div>                  
                  </div>
                 
                </li>
                <li className='nav-item dropdown has-megamenu'>
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Solutions
                  </Link>
                  <div className="dropdown-menu megamenu   border-0 py-0 bg-white animation slideDownIn">
                    <div className='container'>
                      <div className="rounded-custom width-full align-items-center align-items-stretch">
                        <div className="dropdown-grid-item">

                          <div className="row">
                          <Fade top>
                            <div className="p-2 col-md-4">
                              <h6 className="drop-heading p-3 pt-0 pb-2">Support & Success</h6>
                              <Link to="/Support" className="dropdown-link">
                                <div className='drop-title'>Support</div>
                              </Link>
                              <Link to="/Technologies" className="dropdown-link">
                                <div className='drop-title'>Technologies</div>
                              </Link>
                              <Link to="/Services" className="dropdown-link">
                                <div className='drop-title'>Services</div>
                              </Link>
                              <Link to="/Community" className="dropdown-link">
                                <div className='drop-title'>Community</div>
                              </Link>


                            </div>
                            <div className="p-2  col-md-8">
                              <h6 className="drop-heading p-3 pt-0 pb-2">Support Plans</h6>
                              <div className='row'>
                                <div className='col-md-6 col-lg-4'>
                                  <Link to="/" className="dropdown-link">
                                    <div className="dropdown-info">
                                      <div className="drop-title">Classic Support</div>
                                      <p className="drop-decription">
                                      First light to twilight, we're your weekday lifeline, spanning emails, calls, chats, and remote magic.
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                                <div className='col-md-6 col-lg-4'>
                                  <Link to="/" className="dropdown-link">
                                    <div className="dropdown-info">
                                      <div className="drop-title">Premium Support</div>
                                      <p className="drop-decription">
                                      Five days' round the clock, committed support keeps the solutions flowing.
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                                <div className='col-md-6 col-lg-4'>
                                  <Link to="/" className="dropdown-link">
                                    <div className="dropdown-info">
                                      <div className="drop-title">Enterprise Support</div>
                                      <p className="drop-decription">
                                      A personal representative ensures assistance by round-the-clock for swift issue resolution with expert guidance.
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                              </div>




                            </div>
                            </Fade>
                          </div>



                        </div>

                      </div>
                    </div>
                  </div>
                </li>
                <li className='nav-item dropdown  has-megamenu'>
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Resources
                  </Link>
                  <div className="dropdown-menu  megamenu border-0 py-0 bg-white">
                    <div className='container'>
                      <div className="rounded-custom width-full align-items-center align-items-stretch">
                        <div className="dropdown-grid-item">

                          <div className="row">
                          <Fade top>
                            <div className="p-2 col-md-3">
                              <h6 className="drop-heading p- pt-0 pb-0">Company</h6>
                              <Link to="/about" className="dropdown-link">
                                <div className='drop-title'>About</div>

                              </Link>
                              <Link to="/leadership" className="dropdown-link">
                                <div className='drop-title'>Leadership</div>
                              </Link>
                              <Link to="/career" className="dropdown-link">
                                <div className='drop-title'>Career</div>
                              </Link>
                              <Link to="/customers" className="dropdown-link">
                                <div className='drop-title'>Customers</div>
                              </Link>
                              <Link to="/contact" className="dropdown-link">
                                <div className='drop-title'>Contact</div>
                              </Link>

                            </div>
                            <div className="p-2  col-md-9">
                              <div>
                                {/* <h6 className="drop-heading mb-4 mt-3">Software</h6> */}

                                <div className='row'>
                                  <div className='col-lg-6 mt-4'>
                                    <div className='position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom'>
                                      <div className='cta-left-info mb-2'>
                                        <h5>Utilize your software</h5>
                                        <p>
                                        Progressively innovate models and offer unique benefits for diverse integrated markets.{' '}
                                        </p>
                                      </div>

                                      <div className='cta-img position-absolute right-0 bottom-0'>
                                        <img
                                          src='assets/img/cta-img-1.png'
                                          alt='cta'
                                          className='img-fluid'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-lg-6 mt-4'>
                                    <div className='position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom'>
                                      <div className='cta-left-info mb-2'>
                                        <h5>Stay in the loop with updates</h5>
                                        <p>
                                        Receive immediate notifications that ensure you're intricately linked to the rhythm of ongoing events.{' '}
                                        </p>
                                      </div>

                                      <div className='cta-img position-absolute right-0 bottom-0'>
                                        <img
                                          src='assets/img/cta-img-2.png'
                                          alt='cta img'
                                          className='img-fluid'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </Fade>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
               

              </ul>
            
            </div>

            <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
              {/* <Link
                to="/login"
                className="btn btn-link text-decoration-none me-2 fs-18 fw-400"
              >
                Log In
              </Link> */}
              <Link to="/request-for-demo" className="btn btn-outline-dark btn-demo">
                Book a Free Demo
              </Link>
            </div>
            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
