import React from 'react';
import { Link } from 'react-router-dom';

const OffCanvasMenu = () => {
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
      >
        <div className="offcanvas-header d-flex align-items-center">
          <Link
            to="/"
            className="d-flex align-items-center mb-md-0 text-decoration-none"
          >
            <img
              src="assets/img/logo-color.svg"
              alt="logo"
              className="img-fluid ps-2"
            />
          </Link>
          <button
            type="button"
            className="close-btn text-dark"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
           <i className="fa-sharp fa-regular fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Products
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom width-half">
                  <div className="dropdown-grid-item">                   
                  <Link to="/zentixs-docs-sop-document-review-control" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/docs.svg'
                                    alt='Zentixs docs'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Docs</div>
                                  <p className="drop-decription">
                                  Organize your documents with our cutting-edge solution.
                                    {/* It has provision to create a dynamic workflow for reviewing and finalizing the document.   */}
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-talent-lms-training-management" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/talent.svg'
                                    alt='Zentixs talent'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Talent</div>
                                  <p className="drop-decription">
                                  Cultivating quality, constructing an intelligent organization.
                                    {/* All the aspect of employee training as per assigned matrix and personal record is covered with training summary. */}
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-track-qms-quality-management-incident-capa-change-control" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/track.svg'
                                    alt='Zentixs track'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Track</div>
                                  <p className="drop-decription">
                                  Deliver innovations, streamline compliance, and focus on quality.
                                    {/* It involves CAPA, Deviation, CR, Incident Management procedure in an easy manner. */}
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-eye-department-process-supplier-vendor-audit" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/eye.svg'
                                    alt='Zentixs eye'
                                    className='img30'
                                  />
                                </span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Eye</div>
                                  <p className="drop-decription">
                                  Focusing on crucial risks that directly affect the business.
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-lab-lims-analytical-bioanalytical-clinical-in-vitro" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/labs.svg'
                                    alt='Zentixs labs'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Labs</div>
                                  <p className="drop-decription">
                                  Enabling automated integration with compliance and security.
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-sign-electronic-digital-signature" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/sign.svg'
                                    alt='Zentixs sign'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Sign</div>
                                  <p className="drop-decription">
                                  Elevate your decorum with the precision of a digital sign.
                                    {/* In place of keeping self busy on hard copies for just minute work, this is compatible using drag and drop action to make it digital signature. */}
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-caliber-instrument-equipment-asset-calibration" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/calibar.svg'
                                    alt='Zentixs calibar'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Caliber</div>
                                  <p className="drop-decription">
                                  Maximize equipment lifecycle, efficiency and compliance.
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-log-eln-electronic-logbook" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/log.svg'
                                    alt='Zentixs log'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Log</div>
                                  <p className="drop-decription">
                                  Revolutionize data handling by securely and compliantly.
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-sprint-project-master-schedule-study-management" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/sprint.svg'
                                    alt='Zentixs sprint'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Sprint</div>
                                  <p className="drop-decription">
                                  Execute projects flawlessly, monitoring with precision.
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-saga-document-sample-softcopy-archival" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/saga.svg'
                                    alt='Zentixs saga'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Saga</div>
                                  <p className="drop-decription">
                                  Securely consolidate and organize archived data.
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-desk-ticket-support-helpdesk-frontdesk" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/desk.svg'
                                    alt='Zentixs desk'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Desk</div>
                                  <p className="drop-decription">
                                  Enhance customer service to create incredible experiences.
                                  </p>
                                </div>
                              </Link>
                              <Link to="/zentixs-ibook-inventory-chemical-product-store-management" className="dropdown-link">
                                <span className='demo-list'>
                                  <img
                                    src='assets/img/icon/ibook.svg'
                                    alt='Zentixs ibook'
                                    className='img30'
                                  /></span>
                                <div className="dropdown-info">
                                  <div className="drop-title">Zentixs Ibook</div>
                                  <p className="drop-decription">
                                  Optimize internal operations and enrich warehouse efficiency.
                                  </p>
                                </div>
                              </Link>           
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown">
            <a
                className="nav-link dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Solutions
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom">
                  <div className="dropdown-grid-item">
                    <h6 className="drop-heading  p-3 pt-0 pb-2">Support & Success</h6>
                    <Link to="/Support" className="dropdown-link">
                                <div className='drop-title'>Support</div>
                              </Link>
                              <Link to="/Certificate" className="dropdown-link">
                                <div className='drop-title'>Training & Certification</div>
                              </Link>
                              <Link to="/Services" className="dropdown-link">
                                <div className='drop-title'>Services</div>
                              </Link>
                              <Link to="/Community" className="dropdown-link">
                                <div className='drop-title'>Community</div>
                              </Link>
                  </div>
                  <div className="dropdown-grid-item radius-right-side">
                  <h6 className="drop-heading p-3 pt-0 pb-2">Support Plans</h6>
                  <Link to="/" className="dropdown-link">
                                    <div className="dropdown-info">
                                      <div className="drop-title">Classic Support</div>
                                      <p className="drop-decription">
                                      First light to twilight, we're your weekday lifeline, spanning emails, calls, chats, and remote magic.
                                      </p>
                                    </div>
                                  </Link>
                                  <Link to="/" className="dropdown-link">
                                    <div className="dropdown-info">
                                      <div className="drop-title">Premium Support</div>
                                      <p className="drop-decription">
                                      Five days' round the clock, committed support keeps the solutions flowing
                                      </p>
                                    </div>
                                  </Link>
                                  <Link to="/" className="dropdown-link">
                                    <div className="dropdown-info">
                                      <div className="drop-title">Enterprise Support</div>
                                      <p className="drop-decription">
                                      A personal representative ensures assistance by round-the-clock for swift issue resolution with expert guidance
                                      </p>
                                    </div>
                                  </Link>
                  </div>
                </div>
              </div>
            </li>
        
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Resources
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom">
                  <div className="dropdown-grid-item">
                    <h6 className="drop-heading">Company</h6>
                    <Link to="/About" className="dropdown-link">
                                <div className='drop-title'>About</div>

                              </Link>
                              <Link to="/Leadership" className="dropdown-link">
                                <div className='drop-title'>Leadership</div>
                              </Link>
                              <Link to="/Career" className="dropdown-link">
                                <div className='drop-title'>Career</div>
                              </Link>
                              <Link to="/Customers" className="dropdown-link">
                                <div className='drop-title'>Customers</div>
                              </Link>
                              <Link to="/Contact" className="dropdown-link">
                                <div className='drop-title'>Contact</div>
                              </Link>
                  </div>
                  <div className="dropdown-grid-item radius-right-side bg-light">                  
                  <div className='position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom mb-3'>
                                      <div className='cta-left-info mb-2'>
                                        <h5>Utilize your software</h5>
                                        <p>
                                        Progressively innovate models and offer unique benefits for diverse integrated markets.{' '}
                                        </p>
                                      </div>

                                      <div className='cta-img position-absolute right-0 bottom-0'>
                                        <img
                                          src='assets/img/cta-img-1.png'
                                          alt='cta'
                                          className='img-fluid'
                                        />
                                      </div>
                                    </div>
                                    <div className='position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom'>
                                      <div className='cta-left-info mb-2'>
                                        <h5>Stay in the loop with updates</h5>
                                        <p>
                                        Receive immediate notifications that ensure you're intricately linked to the rhythm of ongoing events.{' '}
                                        </p>
                                      </div>

                                      <div className='cta-img position-absolute right-0 bottom-0'>
                                        <img
                                          src='assets/img/cta-img-2.png'
                                          alt='cta img'
                                          className='img-fluid'
                                        />
                                      </div>
                                    </div>                  
              
                  </div>
                </div>
              </div>
            </li>
          </ul>
     
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;
