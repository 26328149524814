import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroDesk from '../components/Product/Herodesk';
import DeskContent from '../components/Product/DeskContent';
import DeskContentTwo from '../components/Product/DeskContentTwo';
import DeskContentThree from '../components/Product/DeskContenThree';
import DeskContenFour from '../components/Product/DeskContenFour';
import DeskContenFive from '../components/Product/DeskContenFive';
import FaqTwo from '../components/faqs/FaqTwo';

const Lims = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Desk | Customer Service Helpdesk Software | Adrta'
      description='zentixs desk is a customer service help desk software that provides online support for happier customers, empowered agents, and healthier businesses.' />
      <Navbar/>
      <HeroDesk /> 
      <DeskContent />  
      <DeskContentTwo />  
      <DeskContentThree />
      <DeskContenFour />
      <DeskContenFive />     
      <FaqTwo />
      <ContactFormThree />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Lims;
