import React from 'react';
import SectionTitle from '../common/SectionTitle';

const EyeContentThree = () => {
  return (
    <>
      <section className='why-choose-us pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-lg-between justify-content-center align-items-center'>
            <div className='col-lg-5 col-md-7 order-1 order-lg-0'>
              <div className='why-choose-img position-relative '>
                <img
                  src='assets/img/audit-02.jpg'
                  className='img-fluid rounded-custom'
                  alt='duel-phone'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <div className='why-choose-content'>
                <div className='mb-32'>
                  <SectionTitle
                    title='Streamlining Audits for Diverse Scenarios'                   
                    leftAlign
                  />
                </div>
              <p>Scheduling of vendor audits, study audits, process audits, and facility audits, among others. The meticulously designed plan is easily shared with auditors, who subsequently provide a comprehensive checklist for each audit. The audit checklist master, a core feature, can be tailored to suit specific audit processes, facilitating efficient updates by auditors. Our entirely paperless system enables auditors to share findings with process owners through both mobile and desktop applications.
</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EyeContentThree;
