import React, { Component } from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroOne from './HeroOne';
import PageMeta from '../../components/common/PageMeta';
import TestimonialOne from '../../components/testimonial/TestimonialOne';
import FooterOne from '../../layout/Footer/FooterOne';
import TabOne from '../../components/tabs/TabOne';
import FeatureTwo from "../../components/features/FeatureTwo";
import FeatureImgFour from '../../components/features/FeatureImgFour';
import WorkProcess from '../../components/work-process/WorkProcess';
import TrustedUsers from '../../components/work-process/TrustedUsers';



class HomeSassOne extends Component {
  render() {
    return (
      <Layout>
        <PageMeta title='Adrta | Pioneering Software for Business' description='Zentixs is built for pharmaceuticals, research, laboratories and healthcare industries. The software is validated and complied with 21 CFR Part 11, EU Annex 11, HIPAA and GxP guidelines.' />
        <Navbar />
        <HeroOne />
        <TabOne />     
        <FeatureTwo />   
        <TestimonialOne />     
        <FeatureImgFour />       
        <WorkProcess />
        <TrustedUsers />
        <FooterOne footerLight />
      </Layout>
    );
  }
}
export default HomeSassOne;
