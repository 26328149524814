import React from 'react';
import Reveal from 'react-reveal/Reveal';


const EyeContentTwo = () => {
  return (
    <>
      <section className='feature-section-two ptb-120 '>
        <div className='container'>
          <div className='rounded-custom light-red-bg'>
          <div className='row align-items-center justify-content-center '>
          <h2 className='title2 text-center pt-5'>Why choose Zentixs Eye
</h2>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>

                <ul className='list-unstyled d-flex flex-wrap text-dark-gray eye-list'>
                  <li className='py-1'>
                  <i className="fa-duotone fa-circle-check text-danger"></i>
                  Effortless audit management for seamless compliance and evaluation.
                  </li>
                  <li className='py-1'>
                  <i className="fa-duotone fa-circle-check text-danger"></i>
                  Unified platform for collaborative audits, enhancing accountability and trail.
                  </li>
                  <li className='py-1'>
                  <i className="fa-duotone fa-circle-check text-danger"></i>
                  Categorize, recommend, and track for efficient audit management and resolution.
                  </li>
                  <li className='py-1'>
                  <i className="fa-duotone fa-circle-check text-danger"></i>
                  Simplify issue resolution, CAPA, and compliance with streamlined processes.
                  </li>
                  <li className='py-1'>
                  <i className="fa-duotone fa-circle-check text-danger"></i>
                  Generate customizable reports, save time, and communicate findings effectively.
                  </li>
                  <li className='py-1'>
                  <i className="fa-duotone fa-circle-check text-danger"></i>
                  Automate closure upon satisfactory responses, ensuring quality assurance.
                  </li>
  
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
            <Reveal effect="fadeinbottom">
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/audit-01.png'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
              </Reveal>
            </div>
          </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EyeContentTwo;
