import React from "react";
import { Link } from "react-router-dom";

const EyeContent = () => {
  return (
    <section className="cyber-features pt-100 pb-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-7">
            <div className="section-heading text-center mb-5">              
              <h2 className="title2"> Auditing Versatility: <br />One Software, Infinite Capabilities</h2>
              
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
                <i className="far fa-list"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Organize and Plan Audits</h3>
                <p>
                Streamline your audit planning process by setting up automated schedules, assigning responsibilities, and receiving timely notifications. Stay ahead of compliance requirements and ensure thorough evaluations without the hassle of manual coordination.
                </p>
              </div>

            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
                <i className="far fa-cloud"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Initiate Audits with Streamlined Review</h3>
                <p>
                Take control of your audit lifecycle by initiating assessments, assigning tasks, tracking progress, and facilitating collaborative reviews within a unified platform. Enhance accountability and ensure a comprehensive audit trail.
                </p>
              </div>
 
            </div>
          </div>
          <div className="col-lg-4  d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
                <i className="far fa-database"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Complete Oversight and Closure Control</h3>
                <p>
                Effortlessly categorize findings as major, minor, or critical, while generating actionable recommendations for enhanced outcomes. Document observations and effortlessly track progress through each audit stage. Efficiently audit closure, ensuring timely and effective resolution of identified issues.
                </p>
              </div>

            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
                <i className="far fa-door-closed"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Issue Control: CAPA with Due Dates</h3>
                <p>
                Discover the efficiency of Response Track, streamlining issue resolution and CAPA processes. Record and track responses, seamlessly integrate due dates, and maintain control of corrective measures. Achieve efficient issue resolution and compliance, making CAPA management seamless within your audit workflow.

                </p>
              </div>

            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
                <i className="far fa-shield-check"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Instant Audit Report Generation</h3>
                <p>
                Create comprehensive audit reports with ease, collating observations, recommendations, and findings. Generate professional, customizable reports for stakeholders, saving time and ensuring accurate communication of audit results.
                </p>
              </div>

            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="cyber-single-service bg-white rounded-custom mb-30 eye-feature-box">
              <div className="feature-icon pb-5 rounded light-red-bg text-danger mb-4">
                <i className="far fa-server"></i>
              </div>
              <div className="cyber feature-info-wrap">
                <h3 className="h5">Smart Audit Completion</h3>
                <p>
                Utilize predefined criteria to evaluate responses, ensuring their satisfactory nature. Once approved, audits are automatically marked as complete, streamlining the closure process while maintaining a high standard of quality assurance.
               </p>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EyeContent;
