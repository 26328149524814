import React from 'react';
import SectionTitle from '../common/SectionTitle';

const QmsContent = () => {
  return (
    <>
      <section className='promo-section ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                title='Quality Enchantment for Data, Compliance, and Collaboration'             
                  centerAlign
              />
            </div>
          </div>
          <div className='row '>
            <div className='col-lg-3 col-md-4  d-flex align-items-stretch'>
              <div className='promo-single position-relative bg-white rounded-custom p-4 mb-4'>
                <div className='promo-icon mb-32  '>
               <i className='fa-sharp fa-solid fa-dharmachakra text-primary fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Change Control</h3>
                  <p className='mb-0'>
                  Streamlines a structured process by meticulously documenting initiation dates, originators, recipients, and change types. It facilitates seamless collaborative approvals, integrates Quality Assurance evaluations, and tracks the evolution from implementation to closure, ensuring unwavering adherence to quality standards, safety protocols, and regulatory compliance
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-3 col-md-4  d-flex align-items-stretch'>
              <div className='promo-single position-relative bg-white rounded-custom p-4 mb-4'>
                <div className='promo-icon mb-32 '>                
                  <i className='fa-sharp fa-regular fa-notes-medical text-danger fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Incident</h3>
                  <p className='mb-0'>
                  Navigate the incident lifecycle effortlessly. Identify, log, categorize, and prioritize incidents seamlessly. Swiftly escalate and diagnose issues, facilitating thorough investigation and resolution for seamless recovery. The system ensures closure, proactive management, and tracking of incidents.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-3 col-md-4  d-flex align-items-stretch'>
              <div className='promo-single position-relative bg-white rounded-custom p-4 mb-4'>
                <div className='promo-icon mb-32 '>   
                     
                  <i className='fa-regular fa-house-medical text-dark fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Corrective and Preventive Action (CAPA)</h3>
                  <p className='mb-0'>
                  From distinctive CAPA numbers to departmental signatures, the form's journey begins. It encapsulates nonconformity, corrective action, and alignment, fostering cross-functional brilliance. Unveil investigations, root cause analyses, and approvals. Preventive actions guide ahead. QA's vigilance ensures secure implementations.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-3 col-md-4  d-flex align-items-stretch'>
              <div className='promo-single position-relative bg-white rounded-custom p-4 mb-4'>
                <div className='promo-icon mb-32 '>                           
                  <i className='fa-sharp fa-solid fa-prescription-bottle text-success fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Deviation</h3>
                  <p className='mb-0'>
                  Ensures operational excellence by addressing planned and unplanned deviations. It differentiates major and minor impacts, handling quality and compliance for major deviations and adhering to GMP standards for minor ones. The software facilitates risk assessment for planned deviations, streamlines investigation for unplanned deviations, and maintains documentation for compliance
                  </p>
                </div>
              </div>
            </div>
         
            <div className='col-lg-3 col-md-4  d-flex align-items-stretch'>
              <div className='promo-single position-relative bg-white rounded-custom p-4 mb-4'>
                <div className='promo-icon mb-32 '>        
                
                  <i className='fa-sharp fa-regular fa-asterisk text-warning fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Risk Management</h3>
                  <p className='mb-0'>
                  Seamlessly integrates the six crucial aspects of risk management—Identification, Analysis, Evaluation, Reduction, Acceptance, and Review—to effectively manage risks across all processes. Adeptly addressing uncertainties, optimizing opportunities, and strategically safeguarding against potential harm while maximizing overall benefits.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-3 col-md-4  d-flex align-items-stretch'>
              <div className='promo-single position-relative bg-white rounded-custom p-4 mb-4'>
                <div className='promo-icon mb-32 '>              
              
                  <i className='fa-sharp fa-regular fa-vials text-info fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Extension</h3>
                  <p className='mb-0'>
                  Extensions are granted when CAPA, Change Control, or Incident due dates are not met. Users must furnish a title, justification, and impact assessment for the extension request. Live chat is available with the Head or QA personnel. The extension request undergoes an approval process.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-3 col-md-4  d-flex align-items-stretch'>
              <div className='promo-single position-relative bg-white rounded-custom p-4 mb-4'>
                <div className='promo-icon mb-32 '>       
                
                  <i className='fa-sharp fa-light  fa-ballot-check text-danger fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Complaint</h3>
                  <p className='mb-0'>
                  Complaint categorization prioritizes Critical Complaints, which pose potential harm or even fatality. The process covers reception, review, investigation, CAPA if necessary, and closure endorsed by QA. Timely resolutions - 3 days for critical, 7 for major, 15-30 for minor - affirm our commitment to quality and patient safety.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-3 col-md-4  d-flex align-items-stretch'>
              <div className='promo-single position-relative bg-white rounded-custom p-4 mb-4'>
                <div className='promo-icon mb-32 '>                
                  <i className='fa-regular fa-hourglass text-primary fa-3x'></i>
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>OOS, OOC, and OOT</h3>
                  <p className='mb-0'>
                  The software offers robust quality event management handling Out of Specification (OOS), Out of Calibration (OOC), and Out of Trend (OOT) incidents. Through structured processes, analysis, and collaborative actions, it ensures compliant deviation handling and preventive measures, meeting global regulations.
                 </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            
          </div>
       
        </div>
      </section>
    </>
  );
};

export default QmsContent;
