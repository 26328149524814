import React from 'react';

const EyesContentFour = () => {
    return (
        <>
            <section className='ptb-120 light-red-bg'>
                <div className='container'>
                    <div class="row justify-content-center text-center">
                        <div className='col-md-10'>
                        <h2 className='title2 pb-2 '>Secure software for your organization</h2> 
                        <p className=''>Efficiently schedule diverse audits, share meticulous plans, receive detailed checklists from auditors, tailor checklist masters for specific processes, <br />and enable paperless sharing of findings via mobile and desktop apps.
</p>                         
                        </div>
                    </div>
                    <div className='pb-0 text-center pt-5'>
                        <img
                            src='assets/img/eye.png'
                            alt='zentixs aboutus'
                            className='img-fluid'
                        />
                    </div>

                </div>
            </section>
        </>
    );
};

export default EyesContentFour;