import { Link } from 'react-router-dom';
import React from 'react';


const DocsContent = () => {

  return (
    <>
      <section className='app-two-feature ptb-120'          
      >       

        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='section-heading text-center' >
                <h2 className='title2'>Explore Premium Features</h2>
                <p>
                Launch a limitless exploration of document creation through our intuitive digital arena
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-xl-12'>
              <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-one me-3 mb-4 mb-md-0'>
                      <i className='far fa-file-edit'></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Beyond Compliance: Document Review and Approval</h3>
                      <p className='mb-0'>
                      Experience the pinnacle of efficient and error-proof document control with our pioneering option. Seamlessly manage, review, and approve documents within a secure digital environment. Say goodbye to oversights and welcome a new era of precision and compliance in your documentation processes.
                      </p>
                    
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-two me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-file-check"></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Substantial Audit trail</h3>
                      <p className='mb-0'>
                      Zentixs Docs presents a secure electronic document control system that delivers an impeccable, time-stamped audit trail surpassing the rigorous demands of 21 CFR part 11. Our solution adeptly captures the identity of each record creator or modifier, precisely logging timestamps and changes for comprehensive accountability.
                      </p>                     
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-six me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-ballot-check"></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Never miss a Document Number</h3>
                      <p className='mb-0'>
                      Zentixs Docs every document is assigned a distinct and controlled identification number. This meticulous approach eradicates any potential for duplication, instilling precision in document management. Additionally, the system seamlessly enables the tracking of documents throughout their entire lifecycle.
                      </p>                    
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-five me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-clock"></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Reconciliation, Accountability, and Archive</h3>
                      <p className='mb-0'>
                      Elevate your document management with seamless Reconciliation for consistent data accuracy, heightened Accountability through tracked interactions, collaborative Review cycles for precise content, and a secure Archive feature for historical records. Streamline workflows and enhance efficiency with integrated capabilities, ensuring reliability.
                      </p>                    
                    </div>
                  </div>
                </div>  
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-three me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-lock"></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Security</h3>
                      <p className='mb-0'>
                      Enforcing the paramount principles of 21 CFR Part 11, our electronic document control system at Zentixs Docs prioritizes stringent security measures, ensuring exclusive access for authorized users and upholding strict accountability as dictated by established protocols. Additionally, the software employs an automatic locking mechanism, safeguarding both login and approval processes instantaneously in the face of potential threats.
                      </p>
                     
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-four me-3 mb-4 mb-md-0'>
                    <i class="fa-regular fa-files"></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Easy Access</h3>
                      <p  className='mb-0'>                     
                      Zentixs Docs is web-based, enabling seamless connectivity for all personnel engaged in document and quality control, regardless of their geographical location. This web-based architecture facilitates real-time collaboration and participation from virtually anywhere. Moreover, the system establishes a centralized repository, simplifying the process of accessing, searching, and retrieving electronic documents for all authorized users.
                      </p>                    
                    </div>
                  </div>
                </div>
             

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DocsContent;
