import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import QmsContent from '../components/Product/QmsContent';
import CustomerBrand from '../components/customer/CustomerBrand';
import QmsContentTwo from '../components/Product/QmsContentTwo';
import QmsContentThree from '../components/Product/QmsContentThree';
import QmsContentFour from '../components/Product/QmsContentFour';
import FaqTwo from '../components/faqs/FaqTwo';
import HeroQms from '../components/Product/HeroQms';



const Qms = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Track | Quality Management Software | Adrta' />
      <Navbar/>
      <HeroQms />
      <CustomerBrand  />
      <QmsContent/>
      <QmsContentTwo />
      <QmsContentThree />
      <QmsContentFour />
      <FaqTwo className='pt-120' />
      <ContactFormThree />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Qms;
