import React from 'react';


const DocsContentTwo = () => {
  return (
    <>
      <section className='why-choose-us green-light-bg ptb-120'>
        <div className='container'>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 col-12">
              <div className="section-heading" data-aos="fade-up">
                <h2 className='title2 title3'>Harmonious, Ingenious and Effortless</h2>
                <p className='text-dark-gray'>
                  In a landscape increasingly reliant on technology, the significance of pharmaceutical document control systems becomes paramount. The imperative to manage quality documentation necessitates the presence of an efficient document control system. Bid farewell to the cumbersome and disorderly realm of manual document control as you explore an array of meticulously crafted features designed to cater specifically to the exacting regulatory compliance demands of the pharmaceutical sector.
                </p>
              </div>
            </div>
            <div className='col-lg-5 col-12'>
              <div className="dg-news-img text-end">
                <img
                  src="assets/img/document-process-04.png"
                  className="img-fluid text-end"
                  alt="document process"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DocsContentTwo;
