import React from 'react';
import SectionTitle from '../common/SectionTitle';
import Reveal from 'react-reveal/Reveal';


const LogContent = () => {
  return (
    <>
      <section className='promo-section ptb-120 pb-0'>
        <div className='container'>
          <div className='row justify-content-center text-center  mb-30'>
            <div className='col-lg-7 col-md-10'>
              <h2 className='title2'>E-Logbooks as Key to Efficient Data Governance</h2>
             <p>E-logbooks enhance organizational efficiency through streamlined record-keeping, robust verification, easy template management, and accessible data, promoting accuracy and security while simplifying monitoring and categorization.
</p>
            </div>
          </div>
          <div className='row justify-content-center text-center  mb-30'>
            <div className='col-lg-11 col-md-10'>
          <div className='row align-content-stretch '>
            <div className='col-lg-4 col-md-6 mb-4'>
              
              <div className='features-box position-relative  bg-white   p-5 pb-4'>
                <div className='promo-icon mb-32'>
                  <img  src='assets/img/icon/icon1.svg' />
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Creating, cloning, and verifying the template</h3>
                  <p className='mb-0'>
                  Users can create, update, and delete both master templates and detail templates with multiple sections, allowing for flexible customization. The ability to clone existing templates accelerates the setup process, saving time and effort. Robust verification mechanisms ensure the accuracy and integrity of template content before they are put into use.
                  </p>
                </div>             
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-4'>
              <div className='features-box position-relative  bg-white  p-5 pb-4 mb-4'>
                <div className='promo-icon mb-32'>
                <img  src='assets/img/icon/icon2.svg' />
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Logbook Issuance within Role Framework</h3>
                  <p className='mb-0'>
                  Aligned with the designated role and rights, an authorized individual initiates the issuance process of the logbook. This pivotal step ensures that only accurate and authorized information enters circulation. By adhering to verification protocols and leveraging role-specific permissions, the organization maintains a secure and reliable logbook ecosystem.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-4' >
              <div className='features-box position-relative  bg-white  p-5 pb-4 mb-4'>
                <div className='promo-icon mb-32'>
                <img  src='assets/img/icon/icon3.svg' />
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Streamlining Data Entry with Identical Values</h3>
                  <p className='mb-0'>
                  Users have the flexibility to input identical values for varying results, streamlining the data entry process. This feature eliminates redundancy and simplifies input, particularly in cases where consistent values need to be recorded across different outcomes.

                  </p>
                </div>
              
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-4' >
              <div className='features-box position-relative  bg-white  p-5 pb-4 mb-4'>
                <div className='promo-icon mb-32'>
                <img  src='assets/img/icon/icon4.svg' />
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Streamlined Record Input</h3>
                  <p className='mb-0'>
                  Users effortlessly input records from readings, a process feasible both for individual sections and multiple sections concurrently. This streamlined functionality extends to adding records under the master template. Subsequently, the authorized personnel can verify these entries, ensuring accuracy and completeness.
                  </p>
                </div>
              
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-4' >
              <div className='features-box position-relative  bg-white  p-5 pb-4 mb-4'>
                <div className='promo-icon mb-32'>
                <img  src='assets/img/icon/icon5.svg' />
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>Handling Missing Information</h3>
                  <p className='mb-0'>
                  Users can effortlessly add missing information with remarks. After insertion, records are verified for accuracy and completeness. Upon verification, approval confirms the entry's authenticity. This efficient process swiftly addresses gaps, validates, and approves, ensuring data integrity in the logbook.
                  </p>
                </div>
              
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-4' >
              <div className='features-box position-relative  bg-white pb-4 p-5 mb-4'>
                <div className='promo-icon mb-32'>
                <img  src='assets/img/icon/icon6.svg' />
                </div>
                <div className='promo-info'>
                  <h3 className='h5'>List Augmentation</h3>
                  <p className='mb-0'>
                  The logbook, having been in consistent use over an extended period, requires minor adjustments to accommodate the new list. These enhancements are seamlessly integrated using the 'Parameter' mechanism. After thorough verification, these modifications are applied to the existing template.
                  </p>
                </div>
              
              </div>
            </div>
          </div>
          </div>
          </div>
       
        </div>
      </section>
    </>
  );
};

export default LogContent;
