import React from 'react';
import { Link } from 'react-router-dom';

const LogContentFour = () => {
    return (
        <>
           <section className='customer-review ptb-120 light-red-bg'>
        <div className='container'>
          <div className='row'>
            <div className='section-heading text-center' data-aos='fade-up'>
              <h2 className='fw-medium h4 title3'>
              That's not all!
              </h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-4'>
              <div
                className='
                  review-info-wrap
                  text-center
                  rounded-custom
                  d-flex
                  flex-column
                  h-100
                  p-lg-5
                  p-4
                '
                data-aos='fade-up'
                data-aos-delay='50'
              >     
              <h4>Compliance is the key</h4>      
                <div className='review-info-content mt-2'>
                  <p className='mb-4'>
                  Ensuring precision, accountability, and the creation of dependable records is achieved by embracing a system that flawlessly incorporates user roles, approval workflows, and automatic timestamps.
                  </p>
                </div>

              </div>
            </div>
            <div className='col-lg-4 col-md-4'>
              <div
                className='
                  review-info-wrap
                  text-center
                  rounded-custom
                  d-flex
                  flex-column
                  h-100
                  p-lg-5
                  p-4
                '
                data-aos='fade-up'
                data-aos-delay='100'
              >
            <h4>Granular security controls</h4>      
                <div className='review-info-content mt-2'>
                  <p className='mb-4'>
                  Experience enhanced data protection through granular security controls in the logbook system. Tailor access permissions, safeguard sensitive information, and ensure compliance with personalized user roles and restrictions.
                  </p>
                </div>
    
              </div>
            </div>
            <div className='col-lg-4 col-md-4'>
              <div
                className='
                  review-info-wrap
                  text-center
                  rounded-custom
                  d-flex
                  flex-column
                  h-100
                  p-lg-5
                  p-4
                '
                data-aos='fade-up'
                data-aos-delay='150'
              >
  <h4>Track and measure everything
</h4>     
                <div className='review-info-content mt-2'>
                  <p className='mb-4'>
                  Effortlessly monitor and measure activities with our comprehensive feature. Track progress, gain insights, and make informed decisions by capturing and analysing data across all operations effortlessly.
                  </p>
                </div>     
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    );
};

export default LogContentFour;