import React from 'react';

const DeskContentTwo = () => {
    return (
        <>
            <section>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='col-md-10'>

                            <div className="row" >
                                <div className='col-md-6'>
                                    <div>
                                        <h2 className='title2 pb-2'>Who says customer <br />service is tough?</h2>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <p >
                                        Conduct live user sessions and interviews with multiple participants, record, and take notes simultaneously. Auto-transcribe videos to save time and simplify reviewing recorded interviews.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pb-0 text-center' style={{ top: '100px', position: 'relative' }}>
                        <img
                            src='assets/img/dashboard-img.png'
                            alt='zentixs aboutus'
                            className='img-fluid'
                        />
                    </div>

                </div>
            </section>
        </>
    );
};

export default DeskContentTwo;