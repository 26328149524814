import React from 'react';
import { Link } from 'react-router-dom';
import HeroTitle from '../../components/common/HeroTitle';

const HeroLog = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 position-relative overflow-hidden red-bg-gradient'      
      >
        <div className='container'>
          <div className='row justify-content-center text-center'>
            <div className='col-xl-8 col-lg-10 mb-5'>
              <div className='hero-content-wrap'>
                <h2 className='fw-bold display-5'> Simplifying Occurrent Records with <span className='text-red'>E-Logbook</span></h2>
                <p className='text-dark lead'> Efficiently manage logbooks with customizable templates, cloning, verification, access control, archival, hierarchy, data completion, parameters, and barcode-driven monitoring.</p>
                
                <div
                  className='action-btns text-center pt-4'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >     
                </div>
              </div>
            </div>
            <div className='col-lg-9'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='200'
              >
            
                <img
                  src='assets/img/dashboard-img.png'
                  alt=''
                  className='img-fluid position-relative rounded-custom mt-5'
                />
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default HeroLog;
